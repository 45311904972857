/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import logo from '../images/bricoLogo.png'

function SEO({ description, lang, meta, title, siteUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            url
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const Url = site.siteMetadata.url
  return (

    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: Url,
        },
        {
          name: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: logo,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:site`,
          content: Url,
        },
        {
          name: `twitter:image`,
          content: logo,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://bricobarriere.be",
          "name": "Brico Barrière",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Saint-Gilles",
            "addressRegion": "Bruxelles",
            "postalCode": "1060",
            "streetAddress": "Rue Théodore Verhaegen 64"
          },
          "email": "info@bricobarriere.be",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+3225348797",
            "contactType": "Customer Support"
          }
        }
      `}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
