import React from "react"
import Logo from '../images/bricoLogo.png'


import Scrollspy from "react-scrollspy"
import { Navbar, Nav } from "react-bootstrap"
import Scroller from './scroller'



export default class Header extends React.Component {
  constructor(props) {
    super(props);
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this);
    // this.state = {
    //   status: 'close',
    //   color: 'red'
    // }
  }
  /* eslint-disable */
  // componentDidMount() {
  //   var d = new Date();
  //   const n = d.getDay();
  //   console.log(n)
  //   const hour = new Date().getHours();
  //   console.log(hour)
  //   if (n == 1 || n == 2 || n == 3 || n == 4 || n == 6 && hour <= 19 || hour >= 7) {
  //     console.log('yes')
  //     this.setState({
  //       status: 'open',
  //       color: 'green'
  //     });
  //   }

  //   if (n == 5 && hour >= 12 || hour <= 14) {
  //     console.log('yes2')
  //     this.setState({
  //       status: 'close',
  //       color: 'red'
  //     });
  //   }

  //   if (n == 7 && hour >= 18 || hour <= 8) {
  //     console.log('yes3')
  //     this.setState({
  //       status: 'open',
  //       color: 'green'
  //     });
  //   }

  // }

  render() {
    return (

      <>
        <Navbar className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav" expand="lg"
          collapseOnSelect={true}>
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top" onClick={Scroller.handleAnchorScroll}>
              <img src={Logo} style={{ width: '200px' }} title="Brico barriere - Vente de tous les materiaux de construction et de gros œuvre ainsi que tout ce qui concerne l'électricité,sanitaire peinture, quincaillerie, outillage,toiture, vêtements de travail, bricolage, isolation, et aussi les outils de jardinage" alt="Brico barriere - Vente de tous les materiaux de construction et de gros œuvre ainsi que tout ce qui concerne l'électricité,sanitaire peinture, quincaillerie, outillage,toiture, vêtements de travail, bricolage, isolation, et aussi les outils de jardinage" />
            </a>
            <Navbar.Toggle aria-controls="navbarResponsive" />
            <Navbar.Collapse id="navbarResponsive">
              <Nav className="navbar-nav ml-auto my-2 my-lg-0">
                <Scrollspy className="navbar-nav"
                  items={["about", "services", "portfolio", "contact"]}
                  currentClassName="active" rootEl={"#mainNav"} offset={-75}>
                  <li className="nav-item">
                    <Nav.Link className={"js-scroll-trigger"} href="#about" onClick={Scroller.handleAnchorScroll} title="À propos de brico Barrière">À propos</Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link className={"js-scroll-trigger"} href="#services" onClick={Scroller.handleAnchorScroll} title="Nos Serivces">Nos Services</Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link className={"js-scroll-trigger"} href="#portfolio" onClick={Scroller.handleAnchorScroll} title="Le magasin brico Barrière">Le magasin</Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link className={"js-scroll-trigger"} href="#contact" onClick={Scroller.handleAnchorScroll} title="Contactez-nous">Contact</Nav.Link>
                  </li>
                  {/* <li className="nav-item openIndicator">
                    <div className={this.state.color}>

                    </div>
                    <p>&nbsp;&nbsp;{this.state.status}</p>
                  </li> */}
                </Scrollspy>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
        <header className="masthead">
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-10 align-self-end">
                <h1 className="text-uppercase text-white font-weight-bold">Brico Barrière</h1>
                <hr className="divider my-4" />
              </div>
              <div className="col-lg-8 align-self-baseline">
                <p className="text-white-75 font-weight-light mb-5">Brico Barrière ouvre le dimanche de 8h à 18h pour permettre aux personnes qui travaillent la semaine de faire leur travaux et bricolage en toute tranquillité</p>
                <a className="btn btn-primary btn-xl js-scroll-trigger" href="#contact" onClick={Scroller.handleAnchorScroll} title="Faite votre commande chez Brico Barrière">Commandez</a>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}



